import React from "react";
import { Helmet } from "react-helmet";

// A reusable SEO component that can be used across different pages
const SEO = ({
	title = "Vector 360",
	description = "Vector 360 BD - Software and Digital Solution",
	keywords = "Vector360, Software , https://vector360bd.com",
	canonicalUrl,
	ogImage = "https://vector360bd.com/logo192.png",
	ogType = "website",
}) => {
	return (
		<Helmet>
			{/* Basic Meta Tags */}
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta name="keywords" content={keywords} />

			{/* Canonical URL */}
			{canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

			{/* Open Graph Meta Tags */}
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:type" content={ogType} />
			{ogImage && <meta property="og:image" content={ogImage} />}

			{/* Twitter Card Meta Tags */}
			{/* <meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:creator" content={twitterHandle} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			{ogImage && <meta name="twitter:image" content={ogImage} />} */}

			{/* Additional Meta Tags */}
			<meta name="robots" content="index, follow" />
			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
		</Helmet>
	);
};
export default SEO;
