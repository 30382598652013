import React, { useState } from "react";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
SwiperCore.use([Navigation, Autoplay]);

function PartnarArea() {
	const [activeIndex, setActiveIndex] = useState(0);

	const partnarSlider = {
		loop: true,
		slidesPerView: 5,
		spaceBetween: 20,
		speed: 1000,
		autoplay: {
			delay: 2500,
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: ".swiper-button-next-c",
			prevEl: ".swiper-button-prev-c",
		},
		onSlideChange: (swiper) => {
			setActiveIndex(swiper.realIndex);
		},
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			992: {
				slidesPerView: 4,
			},
			1200: {
				slidesPerView: 5,
			},
		},
	};

	const partnerImages = [
		"reserveitweb.png",
		"smartfarmer.png",
		"v.png",
		"Govt.png",
		"doict.jpg",
		"lake.png",
		"basecamp.png",
		"neofarmer.png",
		"fpt.png",
		"aep.jpg",
		"ba.jpg",
		"collage.jpg",
		"law.jpg",
		"thm.jpg",
		"priyojon.png",
		"imagedei_logo.jpg",
		"face_class.png",
		"ekabot.png",
	];

	return (
		<section className="our-partner">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-3">
						<div className="sec-title white layout2">
							<span>Satisfied Client</span>
							<h2>Our Partner</h2>
							<div className="-partnerslider-navigator">
								<div className="swiper-button-prev-c">
									<i className="bi bi-chevron-left" />
								</div>
								<div className="swiper-button-next-c">
									<i className="bi bi-chevron-right" />
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-9">
						<Swiper {...partnarSlider} className="swiper partner-slider">
							{partnerImages.map((image, index) => (
								<SwiperSlide key={index} className="swiper-slide">
									<div className="single-partner">
										<img
											src={`${process.env.PUBLIC_URL}/img/partner/${image}`}
											alt="partner-img"
											style={{
												filter:
													index === activeIndex ? "none" : "grayscale(100%)",
												border:
													index === activeIndex
														? "3px solid #ffcb05"
														: "3px solid transparent",
												transition: "border 2s ease, filter 1s ease",
											}}
										/>
									</div>
								</SwiperSlide>
							))}
						</Swiper>
					</div>
				</div>
			</div>
		</section>
	);
}

export default PartnarArea;
