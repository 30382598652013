import React from "react";
import CountUp from "react-countup";
import {
	CompeletedProjectIcon,
	ExperienceIcon,
	ExpertTeamIcon,
	SatisfiedClientIcon,
} from "../../svgs";
function FeaturesArea() {
	return (
		<>
			<section className="features-area ">
				<div className="container">
					<div
						className="title-wrap  wow animate fadeInUp"
						data-wow-delay="200ms"
						data-wow-duration="1500ms"
					>
						<div className="sec-title white">
							<span>Case Study</span>
							<h2>Features</h2>
							<p>
								Choose Vector360 for cutting-edge software solutions, agile
								project management, and a commitment to excellence. With a focus
								on client objectives, transparency, and collaboration,
							</p>
						</div>
					</div>
					<div className="row g-3   align-items-center  justify-content-center">
						<div
							className="col-md-6 col-lg-3 wow animate fadeInUp"
							data-wow-delay="200ms"
							data-wow-duration="1500ms"
						>
							<div className="single-feature">
								<div className="feature-inner">
									<div className="icon">
										<CompeletedProjectIcon width={80} height={80} />
									</div>
									<CountUp end={55} delay={3} duration={3} /> <sup>+</sup>
									<h4>Project Completed</h4>
								</div>
							</div>
						</div>
						{/* <div
							className="col-md-6 col-lg-3 wow animate fadeInUp"
							data-wow-delay="400ms"
							data-wow-duration="1500ms"
						>
							<div className="single-feature">
								<div className="feature-inner">
									<div className="icon">
										<SatisfiedClientIcon width={80} height={80} />
									</div>
									<CountUp end={160} delay={3} duration={3} /> <sup>+</sup>
									<h4>Satisfied Clients</h4>
								</div>
							</div>
						</div> */}
						<div
							className="col-md-6 col-lg-3 wow animate fadeInUp"
							data-wow-delay="600ms"
							data-wow-duration="1500ms"
						>
							<div className="single-feature">
								<div className="feature-inner">
									<div className="icon">
										<ExpertTeamIcon width={80} height={80} />
									</div>
									<CountUp end={50} delay={3} duration={5} /> <sup>+</sup>
									<h4>Expert Teams</h4>
								</div>
							</div>
						</div>
						<div
							className="col-md-6 col-lg-3 wow animate fadeInUp"
							data-wow-delay="800ms"
							data-wow-duration="1500ms"
						>
							<div className="single-feature">
								<div className="feature-inner">
									<div className="icon">
										<ExperienceIcon width={80} height={80} />
									</div>
									<CountUp end={3} delay={1} duration={3} /> <sup>+</sup>
									<h4>Years of Experience</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default FeaturesArea;
