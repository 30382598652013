const data = [
	{
		id: 1,
		title: "MOBILE APP",
		image: "img/project/mobile.jpg",
		category: "developing",
		details: "RESERVEIT BD",
	},
	{
		id: 2,
		title: "WEB APPLICATION",
		image: "img/project/reserveitweb.jpg",
		category: "web",
		details: "RESERVEIT BD",
	},
	{
		id: 3,
		title: "ADMIN PANEL",
		image: "img/project/admin.jpg",
		category: "App",
		details: "RESERVEIT BD",
	},
	{
		id: 4,
		title: "APPLICATION",
		image: "img/project/smartkrishok.webp",
		category: "web",
		details: "SMART KRISHOK",
	},
	{
		id: 5,
		title: "APPLICATION",
		image: "img/project/faceapp.webp",
		category: "web",
		details: "FACE CLASS AI",
	},
	{
		id: 6,
		title: "APPLICATION",
		image: "img/project/priyojon.webp",
		category: "web",
		details: "PRIYOJON - HOME CARE SERVICE",
	},
	{
		id: 7,
		title: "WEB APPLICATION",
		image: "img/project/basecamp.png",
		category: "web",
		details: "FOY’S LAKE BASECAMP",
	},
	{
		id: 8,
		title: "WEB APPLICATION",
		image: "img/project/thebase.png",
		category: "web",
		details: "THE BASECAMP BD",
	},
	{
		id: 9,
		title: "WEB APPLICATION",
		image: "img/project/vps-s.webp",
		category: "web",
		details: "VIRTUAL PROPERTY STUDIO",
	},
	// {
	//     id:7,
	//     title:"3D Design",
	//     image:"img/project/project-5.jpg",
	//     category: "Graphic",
	//     details:"Low Poly Base Mesh",
	// },
	// {
	//     id:8,
	//     title:"3D Design",
	//     image:"img/project/project-1.jpg",
	//     category: "Graphic",
	//     details:"Low Poly Base Mesh",
	// },
	// {
	//     id:9,
	//     title:"UI Kit",
	//     image:"img/project/project-2.jpg",
	//     category: "UI",
	//     details:"Low Poly Base Mesh",
	// },
	// {
	//     id:10,
	//     title:"UI Kit",
	//     image:"img/project/project-4.jpg",
	//     category: "UI",
	//     details:"Low Poly Base Mesh",
	// },
	//   {
	//     id: 11,
	//     title: "App",
	//     image: "img/project/project-3.jpg",
	//     category: "App",
	//     details: "Mobile Crypto Wallet",
	//   },
	// {
	//     id:12,
	//     title:"App",
	//     image:"img/project/project-1.jpg",
	//     category: "App",
	//     details:"Mobile Crypto Wallet",
	// },
];

export default data;
