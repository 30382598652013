import React from "react";

function SubscribArea() {
	return (
		<>
			<section className="subscribe-newsletter sec-mar-top">
				<div className="container">
					<div className="news-letter-content">
						<div className="row align-items-center">
							<div
								className="col-lg-6 wow animate fadeInLeft"
								data-wow-delay="200ms"
								data-wow-duration="1500ms"
							>
								<div className="subscribe-cnt">
									<span>Review</span>
									<h3>Your Review</h3>
									<strong>Help Us to Improve</strong>
								</div>
							</div>
							<div
								className="col-lg-6 wow animate fadeInRight"
								data-wow-delay="200ms"
								data-wow-duration="1500ms"
							>
								<div className="subscribe-form">
									{/* <form
										onScroll={(e) => e.preventDefault()}
										action="#"
										method="post"
									>
										<input
											type="email"
											name="email"
											placeholder="Type Your Email"
										/>
										<input type="submit" defaultValue="connect" />
									</form> */}
									<div className="review">
										<div className="review-btn">
											<a
												href="https://www.google.com/search?q=vector360&oq=ve&gs_lcrp=EgZjaHJvbWUqDggAEEUYJxg7GIAEGIoFMg4IABBFGCcYOxiABBiKBTINCAEQLhjUAhixAxiABDIGCAIQRRg5Mg0IAxAuGNQCGLEDGIAEMg0IBBAuGNQCGLEDGIAEMg0IBRAuGNQCGLEDGIAEMhAIBhAAGIMBGLEDGIAEGIoFMg0IBxAAGIMBGLEDGIAEMhMICBAuGIMBGMcBGLEDGNEDGIAEMgoICRAAGLEDGIAE0gEIMTA1NWowajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x3755bf51a47c6f33:0x980cff0ef6e8f47,3,,,,"
												target="_SEJ"
												rel="noreferrer"
											>
												Give us a review
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default SubscribArea;
