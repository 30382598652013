import React from "react";
import AnimateLine from "../../common/AnimateLine";
import SubscribArea from "../../common/SubscribArea";
import ProjectWrap from "../project/ProjectWrap";
// import PriceCardWrap from "../service/PriceCardWrap";
// import TeamWrap from "../team/TeamWrap";
import AboutArea from "./AboutArea";
import FeaturesArea from "./FeaturesArea";
import HeroArea from "./HeroArea";
// import LatesPost from "./LatesPost";
import PartnarArea from "./PartnarArea";
import ServiceArea from "./ServiceArea";
import { Helmet } from "react-helmet";
import SEO from "../../../config/seo.config";
// import Testimonial from "./Testimonial";

function HomePageOne() {
	return (
		<>
			<SEO
				canonicalUrl={"https://vector360bd.com"}
				title="Vector360"
				description="Welcome to Vector360, your one-stop solution for digital innovation, providing exceptional services in web development, branding, and more."
			/>
			<div className="creasoft-wrap">
				<AnimateLine />
				<HeroArea />
				<ServiceArea />
				<AboutArea image="/img/logo.svg" />
				<FeaturesArea />
				<ProjectWrap />
				<PartnarArea />
				{/* <PriceCardWrap /> */}
				{/* <Testimonial /> */}
				{/* <TeamWrap /> */}
				{/* <LatesPost /> */}
				<SubscribArea />
			</div>
		</>
	);
}

export default HomePageOne;
