import "./index.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Layout from "./components/App";
import MainLayout from "./components/layout/MainLayout";
import Preloader from "./components/layout/Preloader";

// Lazy load all components
const About = React.lazy(() => import("./components/page/about/About"));
const Contact = React.lazy(() => import("./components/page/contact/Contact"));
const ErrorPage = React.lazy(() => import("./components/page/error/ErrorPage"));
const Career = React.lazy(() => import("./components/page/career/Career"));
const ViewCareer = React.lazy(() =>
	import("./components/page/career/view-career")
);
const PrivacyPolicy = React.lazy(() =>
	import("./components/page/privacy/Privacy")
);
const Refund = React.lazy(() =>
	import("./components/page/refund-cancellation/Refund")
);
const Project = React.lazy(() => import("./components/page/project/Project"));
const ProjectDetails = React.lazy(() =>
	import("./components/page/ProjectDetails/ProjectDetails")
);
const Service = React.lazy(() => import("./components/page/service/Service"));
const ServiceDetails = React.lazy(() =>
	import("./components/page/ServiceDetails/ServiceDetails")
);
const PrincipleObjective = React.lazy(() =>
	import("./components/page/principalObjectives/PrincipalObjectives")
);
const InternalMarketing = React.lazy(() =>
	import("./components/page/internalMarketing/InternalMarketing")
);
const Terms = React.lazy(() => import("./components/page/terms/Terms"));
const FeasibilityStudy = React.lazy(() =>
	import("./components/page/feasibility-study")
);
const IctAcademy = React.lazy(() =>
	import("./components/page/feasibility-study/pages/ict-academy")
);
const EDCFeasibilityPage = React.lazy(() =>
	import("./components/page/feasibility-study/pages/edc")
);
const SmartEwasteFeasibilityPage = React.lazy(() =>
	import("./components/page/feasibility-study/pages/waste-management")
);

// Loading fallback component
const LoadingFallback = () => (
	<>
		{" "}
		<Preloader />
	</>
);

function Root() {
	return (
		<BrowserRouter basename="/">
			<Suspense fallback={<LoadingFallback />}>
				<Switch>
					{/*main layout*/}
					<Route exact path="/" component={MainLayout} />

					<Layout>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/about`}
							render={() => <About />}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/contact`}
							render={() => <Contact />}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/career`}
							render={() => <Career />}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/career/:id`}
							render={() => <ViewCareer />}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/error`}
							render={() => <ErrorPage />}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/service`}
							render={() => <Service />}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/service-details`}
							render={() => <ServiceDetails />}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/project-details`}
							render={() => <ProjectDetails />}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/project`}
							render={() => <Project />}
						/>
						<Route
							exact
							path={`/principal-objectives`}
							render={() => <PrincipleObjective />}
						/>
						<Route
							exact
							path={`/internal-marketing`}
							render={() => <InternalMarketing />}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/privacy-policy`}
							render={() => <PrivacyPolicy />}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/terms-of-use`}
							render={() => <Terms />}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/refund`}
							render={() => <Refund />}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/feasibility-study`}
							render={() => <FeasibilityStudy />}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/feasibility-study/Bangabandhu-National-ICT Academy-Feasibility-Study`}
							render={() => <IctAcademy />}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/feasibility-study/edc`}
							render={() => <EDCFeasibilityPage />}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/feasibility-study/Waste-Management`}
							render={() => <SmartEwasteFeasibilityPage />}
						/>
					</Layout>
				</Switch>
			</Suspense>
		</BrowserRouter>
	);
}

export default Root;

ReactDOM.render(
	<React.StrictMode>
		<Root />
	</React.StrictMode>,
	document.getElementById("root")
);
